import React, {useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import {motion, useInView, useAnimation} from "framer-motion";

export const Reveal = ({ children, width = 'fit-content' }) => {
    const ref = useRef(null);   //Koristimo React hook kako bismo napravili referencu ka odredjenom elementu
    const isInView = useInView (ref , {once: true} );          // Ukoliko ne ubacimo once:true svaki put kada prolazimo preko elementa ce se izvristi efekat
    const mainControls = useAnimation();
    const slideControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("end");
            slideControls.start("end");
        }
    }, [isInView]);


    return (
        <div ref={ref} style={{ position: 'relative', width, overflow: 'hidden' }}>
            <motion.div
            variants={{                     //Varijante opisuju stanja kroz koje efekat prolazi (transparentnost i pozicija po y), moze ih biti vise od dva
                start: {opacity:0, y:75},   //Startno stanje, pre prikazivanja teksta
                end: {opacity:1, y:0}       //Krajnje stanje, nakon pokazivanja teksta
            }}
            initial="start"                 //Sa kojom od ranije deklarisanih "varijant"-i pocinjemo
            animate={mainControls}                   //Sa kojom od ranije deklarisanih "varijant"-i zavrsavamo
            transition={{ duration:0.5, delay:0.25}}
            >
                {children}
            </motion.div>

            <motion.div
            variants = {{
                start: {left:0},   //Startno stanje, pre prikazivanja teksta
                end: {left: "100%" }       //Krajnje stanje, nakon pokazivanja teksta
            }}
            initial="start"                 //Sa kojom od ranije deklarisanih "varijant"-i pocinjemo
            animate={slideControls}                   //Sa kojom od ranije deklarisanih "varijant"-i zavrsavamo
            transition={{ duration:0.5, ease:"easeIn"}}
            style={{
                position:"absolute",
                top: 4,
                bottom: 4,
                left: 0,
                right: 0,
                background: "var(--primary-color)",
                zIndex:20
            }}
            />


        </div>
    );
};

Reveal.propTypes = {
    children: PropTypes.element.isRequired,
    width: PropTypes.oneOf(['fit-content', '100%'])
};