import React, { useState } from 'react'
import '../index.css'
import {Link} from 'react-scroll';
import { UilFileDownloadAlt, UilLinkedin, UilYoutube, UilGithub, UilEstate, UilInfoCircle, UilBookOpen, UilBriefcaseAlt, UilEnvelope, UilTimesCircle, UilBars   } from '@iconscout/react-unicons'
import Resume from '../Resume_Dmitar_Popovic.pdf'
import {Reveal} from "./utils/Reveal";

const handleSetActive = (to) => {

  };
const Navbar = ({ currentSection, onSectionChange }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleSetActive = (to) => {
        if (typeof onSectionChange === 'function') {
            onSectionChange(to);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };



    return (
        <header className="sticky-header">
            <div></div>
            <div className="navigation">

                <Link to="home" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('home')}>
                    <Reveal>
                        <div className={`single_nav single_nav-left ${currentSection === 'home' ? 'active-nav' : ''}`}>
                            Home
                        </div>
                    </Reveal>
                </Link>

                <Link to="about" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('about')}>
                    <Reveal>
                        <div className={`single_nav ${currentSection === 'about' ? 'active-nav' : ''}`}>
                            About
                        </div>
                    </Reveal>
                </Link>

                <Link to="skills" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('skills')}>
                     <Reveal>
                        <div className={`single_nav ${currentSection === 'skills' ? 'active-nav' : ''}`}>
                            Skills
                        </div>
                     </Reveal>
                </Link>

                <Link to="projects" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('projects')}>
                    <Reveal>
                        <div className={`single_nav ${currentSection === 'projects' ? 'active-nav' : ''}`}>
                            Projects
                        </div>
                    </Reveal>
                </Link>

                <Link to="contact" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('contact')}>
                    <Reveal>
                        <div className={`single_nav single_nav-right ${currentSection === 'contact' ? 'active-nav' : ''}`}>
                            Contact
                        </div>
                    </Reveal>
                </Link>

            </div>
            <div className="navigation-mobile">

                <Link to="home" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('home')}>
                    <Reveal>
                        <div className={`single_nav_mobile ${currentSection === 'home' ? 'active-nav' : ''}`}>
                            <UilEstate/>
                        </div>
                    </Reveal>
                </Link>

                <Link to="about" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('about')}>
                    <Reveal>
                        <div className={`single_nav_mobile ${currentSection === 'about' ? 'active-nav' : ''}`}>
                            <UilInfoCircle />
                        </div>
                    </Reveal>
                </Link>

                <Link to="skills" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('skills')}>
                    <Reveal>
                        <div className={`single_nav_mobile ${currentSection === 'skills' ? 'active-nav' : ''}`}>
                            <UilBookOpen />
                        </div>
                    </Reveal>
                </Link>

                <Link to="projects" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('projects')}>
                    <Reveal>
                        <div className={`single_nav_mobile ${currentSection === 'projects' ? 'active-nav' : ''}`}>
                            <UilBriefcaseAlt/>
                        </div>
                    </Reveal>
                </Link>

                <Link to="contact" spy={true} smooth={true} offset={-50} duration={500} onSetActive={() => handleSetActive('contact')}>
                    <Reveal>
                        <div className={`single_nav_mobile ${currentSection === 'contact' ? 'active-nav' : ''}`}>
                            <UilEnvelope />
                        </div>
                    </Reveal>
                </Link>

            </div>
            <div className="links">
                <Reveal>
                    <div className="single_link">
                        <a href={Resume}download>
                            <UilFileDownloadAlt/>
                        </a>
                    </div>
                </Reveal>
                <Reveal>
                    <div className="single_link">
                        <a href="https://github.com/AerospaceProgrammer/" target="_blank" rel="noopener noreferrer">
                            <UilGithub size="1.2em"/>
                        </a>
                    </div>
                </Reveal>
                <Reveal>
                    <div className="single_link">
                        <a href="https://www.linkedin.com/in/dmitarpopovic/" target="_blank" rel="noopener noreferrer">
                            <UilLinkedin/>
                        </a>
                    </div>
                </Reveal>
                <Reveal>
                    <div className="single_link-last">
                        <a href="https://www.youtube.com/@DmitarPopovic" target="_blank" rel="noopener noreferrer">
                            <UilYoutube />
                        </a>
                    </div>
                </Reveal>
            </div>
            <div className="links-mobile" id={isMenuOpen ? "menu-open" : "menu-closed"}>
                {isMenuOpen ? (
                    <div className="single_link-mobile" onClick={toggleMenu} style={{ color: 'red' }}>
                        <UilTimesCircle size="1.2em"/>
                    </div>
                ) : (
                    <div className="single_link-mobile" onClick={toggleMenu}>
                        <UilBars/>
                    </div>
                )}

                {isMenuOpen && (
                    <>
                        <div className="single_link-mobile">
                            <a href={Resume} download>
                                <UilFileDownloadAlt size="1.2em"/>
                            </a>
                        </div>

                        <div className="single_link-mobile">
                            <a href="https://github.com/AerospaceProgrammer/" target="_blank" rel="noopener noreferrer">
                                <UilGithub size="1.2em"/>
                            </a>

                        </div>
                        <div className="single_link-mobile">
                            <a href="https://www.linkedin.com/in/dmitarpopovic/" target="_blank" rel="noopener noreferrer">
                                <UilLinkedin size="1.2em"/>
                            </a>
                        </div>
                        <div className="single_link-mobile-last">
                            <a href="https://www.youtube.com/@DmitarPopovic" target="_blank" rel="noopener noreferrer">
                                <UilYoutube size="1.2em"/>
                            </a>
                        </div>
                    </>
                )}
            </div>
        </header>
    )
}

export default Navbar;