import React, {useState} from 'react';
import '../index.css';
import Plane from './images/plane.png';
import PlaneFront from './images/plane-front.png';
import PlaneBack from './images/plane-back.png';
import PlaneTools from './images/plane-tools.png';
import Python from './images/python.png';
import DjangoLogo from './images/Django.png';
import RestAPI from './images/API.png';
import MySQL from './images/mysql.png';
import JS from './images/JS.png';
import ReactPng from './images/React.png';
import HTML from './images/HTML5.png';
import CSS from './images/CSS3.png';
import Git from './images/git.png';
import Hostinger from './images/hostinger.png';
import { UilLeftArrowFromLeft, UilTopArrowFromTop } from '@iconscout/react-unicons'
import { Reveal } from "./utils/Reveal";

const Skills = () => {

    const [hovered, setHovered] = useState('');

    // Event handlers
    const handleMouseEnter = (overlayId) => {
        setHovered(overlayId);

    };

    const handleMouseLeave = () => {
        setHovered('');
    };


    const getDisplayStyle = (overlayId) => {
        switch (hovered) {
            case 'overlay1':
                return overlayId.includes('-tools') || overlayId.includes('-back') || overlayId === 'plane'
                    ? { display: 'none' }
                    : { display: 'flex' };
            case 'overlay2':
                return overlayId === 'plane-tools'
                    ? { display: 'flex' }
                    : overlayId.includes('-front') || overlayId.includes('-back') || overlayId === 'plane'
                        ? { display: 'none' }
                        : {};
            case 'overlay3':
                return overlayId.includes('-front') || overlayId.includes('-tools') || overlayId === 'plane'
                    ? { display: 'none' }
                    : { display: 'flex' };
            default:
                return overlayId === 'plane'
                    ? {display: 'flex'}
                    : { display: 'none' };
        }
    };

    return (
        <div className="skills" id="skills">

                <div className="image-container">

                    <img src={Plane} alt="Whole plane" id="plane" style={getDisplayStyle('plane')} />

                    <div className="scanner" style={getDisplayStyle('plane')}></div>
                    <img src={PlaneBack} alt="Backend skills" id="plane-back" style={getDisplayStyle('plane-back')} />
                    <img src={PlaneFront} alt="Frontend skills" id="plane-front" style={getDisplayStyle('plane-front')} />
                    <img src={PlaneTools} alt="Frontend skills" id="plane-tools" style={getDisplayStyle('plane-tools')} />
                    <div className="overlay1" id="overlay1" onMouseEnter={() => handleMouseEnter('overlay1')} onMouseLeave={handleMouseLeave}></div>
                    <div className="overlay2" id="overlay2" onMouseEnter={() => handleMouseEnter('overlay2')} onMouseLeave={handleMouseLeave}></div>
                    <div className="overlay3" id="overlay3" onMouseEnter={() => handleMouseEnter('overlay3')} onMouseLeave={handleMouseLeave}></div>
                </div>


            <div className="skills-container" id="skills-back" style={getDisplayStyle('plane-back')}>
                <div className="skills-header">Backend</div>
                <div className="skill" >
                    <img className="skill-img" src={Python} alt="Python"></img>
                    <div className="skill-name">Python</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={DjangoLogo} alt="Django logo" />
                    <div className="skill-name">Django</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={RestAPI} alt="RESTful API"></img>
                    <div className="skill-name">REST API</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={MySQL} alt="MySQL"></img>
                    <div className="skill-name">MySQL</div>
                </div>
            </div>

            <div className="skills-container" style={getDisplayStyle('plane-front')} id="skills-front">
                <div className="skills-header">Frontend</div>
                <div className="skill" >
                    <img className="skill-img" src={JS} alt="Python"></img>
                    <div className="skill-name">JavaScript</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={ReactPng} alt="Django logo" />
                    <div className="skill-name">React</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={HTML} alt="RESTful API"></img>
                    <div className="skill-name">HTML</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={CSS} alt="MySQL"></img>
                    <div className="skill-name">CSS</div>
                </div>
            </div>

            <div className="skills-container" style={getDisplayStyle('plane-tools')}  id="skills-tools">
                <div className="skills-header">Tools</div>
                <div className="skill" >
                    <img className="skill-img" src={Git} alt="Git"></img>
                    <div className="skill-name">Git</div>
                </div>
                <div className="skill" >
                    <img className="skill-img" src={Hostinger} alt="Git"></img>
                    <div className="skill-name">Hostinger</div>
                </div>
            </div>

            <div className="skills-container-default" style={getDisplayStyle('plane')} id="skills-default">
                <Reveal>
                    <div className="hover-info-container">
                        <div>
                            <UilLeftArrowFromLeft size="3em"/>
                        </div>
                        <div className="hover-info"> Hover over plane to display skills.</div>
                    </div>
                </Reveal>


                 <div className="hover-info-container-mobile">
                    <div>
                        <UilTopArrowFromTop size="2em"/>
                    </div>
                    <div className="hover-info"> Click on plane to display skills.</div>
                </div>


            </div>

        </div>
    )
}

export default Skills;