import React from 'react'
import '../index.css'
import PropTypes from "prop-types";
import {Reveal} from "./utils/Reveal";

const Projects = () => {
    const YoutubeEmbed = ({ embedId }) => (
        <div className="video-responsive">
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );



YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};
    return (
        <div className="projects" id="projects">
            <div className="project-container">
                <Reveal>
                    <div className="project-header-mobile">HR Management Software</div>
                </Reveal>
                <YoutubeEmbed embedId="92fo5FkgKnw?si=G0TezZg1BP19ARGB" />
                <div className="video-desc-container">
                    <div className="project-header">
                        <Reveal>
                            Management Software
                        </Reveal>
                    </div>
                    <div className="project-description">
                        <Reveal>
                            Care is a comprehensive tool for efficient HR management.
                            Enhance HR management with 'Company Care', a software designed for streamlining HR tasks and improving employee experiences.
                            Key features include Time Management for tracking vacations and leaves, Onboarding and Offboarding for new hires and exiting employees, Annual Performance Reviews, and Travel Requests and Expense Management.
                            It integrates team absences into managers calendars for better planning. The software also manages the company’s organizational structure, aligning the workforce with strategic goals, monitoring expiring employee contracts, and managing travel compensation policies.
                        </Reveal>
                    </div>
                    <div className="project-skills">
                        <Reveal><div className="project-skill">Python</div></Reveal>
                        <Reveal><div className="project-skill">Django</div></Reveal>
                        <Reveal><div className="project-skill">MySQL</div></Reveal>
                        <Reveal><div className="project-skill">Rest API</div></Reveal>
                        <Reveal><div className="project-skill">GIT</div></Reveal>
                        <Reveal><div className="project-skill">Hostinger</div></Reveal>
                        <Reveal><div className="project-skill">JavaScript</div></Reveal>
                        <Reveal><div className="project-skill">React</div></Reveal>
                        <Reveal><div className="project-skill">HTML</div></Reveal>
                        <Reveal><div className="project-skill">CSS</div></Reveal>
                    </div>
                    <Reveal>
                        <div className="source-code-container">
                            <a className="source-code" href="https://www.linkedin.com/in/dmitarpopovic/" target="_blank" rel="noopener noreferrer">
                                Source Code
                            </a>
                        </div>
                    </Reveal>
                </div>
            </div>
        </div>

    )
}

export default Projects;