import React from 'react';
import '../index.css';
import ProfilePhoto from './images/Profile_photo_ 2.png';
import Plane from "./images/plane.png";
import {Reveal} from "./utils/Reveal";

const About = () => {
    return (
        <div className="about" id="about">

            <div className="photo-container">
                <Reveal>
                    <img className="prifile-photo" src={ProfilePhoto} alt="Profile Photo"/>
                </Reveal>
            </div>


            <div className="about-text">
                <Reveal>
                    <p style={{ textIndent: '40px' }}>
                        As a Full-Stack Developer with a decade long background in Aerospace Engineering, I bring a unique blend of technical expertise and analytical problem-solving skills.
                        Fascinated by the design process of complex systems, I find a passion in the world of software development where creativity meets functionality.
                        I thrive in environments that challenge my skills and push the boundaries of my knowledge, always aiming to contribute to innovative projects and collaborating with inspiring colleagues.
                    </p>
                </Reveal>
            </div>
        </div>
    );
}

export default About;
