import React, { useState } from 'react'
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import Skills from './components/Skills'
import Project from './components/Project'
import Contact from './components/Contact'

function App() {
    const [activeSection, setActiveSection] = useState('home');
    return (
        <div>
            <Navbar currentSection={activeSection} onSectionChange={setActiveSection} />
            <Home currentSection={activeSection} onSectionChange={setActiveSection}/>
            <About currentSection={activeSection} onSectionChange={setActiveSection}/>
            <Skills currentSection={activeSection} onSectionChange={setActiveSection}/>
            <Project currentSection={activeSection} onSectionChange={setActiveSection}/>
            <Contact currentSection={activeSection} onSectionChange={setActiveSection}/>
        </div>
  );
}

export default App;